import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  private API = environment.API.USER;

  constructor(private http: HttpClient) { }

  validateEmail (email: string) {
    let params = new HttpParams().set('email', btoa(email))
    return this.http.get(`${this.API}/validate-email-exist`, { params }).toPromise();
  }

  sendEmail (data: any) {
    return this.http.post(`${this.API}/send-email-delete-user`, data).toPromise();
  }

  deleteUser (data: any) {
    return this.http.post(`${this.API}/delete-user-by-id`, data).toPromise();
  }
}
