import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeleteComponent } from './delete/delete.component';
import { EmailComponent } from './email/email.component';
import { FinishedComponent } from './finished/finished.component';

const routes: Routes = [
  {
    path: 'delete',
    component: DeleteComponent
  },
  {
    path: 'email/:email',
    component: EmailComponent
  },
  {
    path: 'finished/:id',
    component: FinishedComponent
  },
  { path: '**', redirectTo: 'delete', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
