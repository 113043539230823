import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private readonly API = environment.SECURITY_API

  constructor(private http: HttpClient) { }

  encrypt(data: any) {
    return this.http.post(`${this.API.ENCRYPT}`, data).toPromise();
  }

  decrypt(data: any): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.API.DECRYPT}`, data).subscribe((response: any) => {
           resolve(response);
        }, (err: any) => reject(err)
      );
    });
  }
}
