<div class="delete-container">
  <h1 class="" style="font-weight: 600;">Lamentamos verte ir</h1>
  <h3 class="mb-4">¿Quieres desactivar tu cuenta?</h3>
  <p>
    Por favor, ingresa el correo electrónico que usaste para crear tu cuenta.
  </p>
  <div class="input-group">
    <span class="input-group-text" [ngClass]="{'error': (deleteControl.invalid && deleteControl.touched) || existEmail}">
      <mat-icon fontSet="material-icons-outlined" class="email-icon">mail</mat-icon>
    </span>
    <input type="text" class="form-control" [ngClass]="{'error': existEmail}" [formControl]="deleteControl" placeholder="Escribe aquí tu correo electrónico" aria-label="Escribe aquí tu correo electrónico" aria-describedby="basic-addon2">
    <span class="input-group-text" id="basic-addon2" [ngClass]="{'error': (deleteControl.invalid && deleteControl.touched) || existEmail}">
      <div class="spinner-border" role="status" *ngIf="processing">
        <span class="visually-hidden">Loading...</span>
      </div>
    </span>
  </div>
  <span class="message error" *ngIf="deleteControl.hasError('email')">Por favor ingresar un correo valido</span>
  <span class="message error" *ngIf="existEmail">La cuenta asociada a este correo ya ha sido eliminada o no existe</span>
  <span class="message" *ngIf="!deleteControl.hasError('email') && !existEmail">*Recuerda que al dar de baja tu cuenta, no será posible recuperarla.</span>
  <button mat-flat-button color="primary" class="button-delete mt-4" [disabled]="deleteControl.invalid || existEmail || processing" (click)="doEmail()">Continuar</button>
</div>
