const BASE_API = 'https://ja4j8mqyia.execute-api.us-east-1.amazonaws.com/dev';
const API_LOGIN = 'https://d4qo4rsz5l.execute-api.us-east-1.amazonaws.com/dev';

export const environment = {
  production: false,
  SECURITY_API: {
		ENCRYPT: BASE_API + '/security/encrypt',
		DECRYPT: BASE_API + '/security/decrypt',
	},
  API: {
    USER: API_LOGIN + '/user'
  }
};

