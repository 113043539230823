<div class="delete-container">
  <img src="assets/mail.gif" alt="GIF" width="150">
  <h1 class="fw-bold">Correo enviado</h1>
  <p class="email-text-middle">
    Hemos enviado un correo a <br>
    <span class="text-decoration-underline" style="color: blue;">{{email ?? 'Correo no disponible'}}</span> <br>
    para confirmar la eliminación de tu cuenta.
  </p>
  <p class="mt-4 email-text-end">
    Por favor, revisa tu bandeja de entrada y haz clic en el enlace de confirmación para completar el proceso.
  </p>
</div>
