import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../services/security.service';
import { DeleteService } from '../services/delete.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-finished',
  templateUrl: './finished.component.html',
  styleUrls: ['./finished.component.scss']
})
export class FinishedComponent implements OnInit {

  id: string | null = '';

  constructor(
    private security: SecurityService,
    private deleteService: DeleteService,
    private route: ActivatedRoute,
    private loader: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.deleteTrader()
  }

  async deleteTrader () {
    try {
      this.loader.show();
      const data = {
        id: this.id ? +this.id : 0
      }
      const encryptedResponse: any = await this.security.encrypt({data});
      const deleteResponse: any = await this.deleteService.deleteUser({ data: encryptedResponse.body.data });
      const decryptedResponse = await this.security.decrypt({data: deleteResponse.data});
      this.loader.hide();
      if (decryptedResponse.body.data.success) {
        const response = decryptedResponse.body.data.message;
        if (response.id === 9) {
          console.log('success');
        } else {
          console.error('error al eliminar trader');
        }
      } else {
        console.error('error al eliminar trader');
      }
    } catch (error) {
      this.loader.hide();
      console.error('error al eliminar trader');
    }
  }

}
