import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SecurityService } from '../services/security.service';
import { DeleteService } from '../services/delete.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit, OnDestroy {

  deleteControl = new FormControl('', [Validators.required, Validators.email]);
  loader = false;
  existEmail = false;
  processing = false;
  destroy$: Subscription;

  constructor(
    private security: SecurityService,
    private deleteService: DeleteService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.destroy$ = this.deleteControl.valueChanges.subscribe(value => {
      if (this.existEmail) {
        this.existEmail = false;
      }
    })
  }

  async validateEmail (): Promise<boolean> {
    try {
      const encryptedResponse: any = await this.deleteService.validateEmail(this.deleteControl.value.trim());
      const decryptedResponse = await this.security.decrypt({data: encryptedResponse.data});
      if (decryptedResponse.body.data.success) {
        const response = decryptedResponse.body.data.message;
        if (response.id === 9) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    } catch (error) {
      return false
    }
  }

  async sendEmail () {
    try {
      const data = {
        email: this.deleteControl.value.trim()
      }
      const encryptedResponse: any = await this.security.encrypt({data});
      const deleteResponse: any = await this.deleteService.sendEmail({ data: encryptedResponse.body.data });
      const decryptedResponse = await this.security.decrypt({data: deleteResponse.data});
      if (decryptedResponse.body.data.success) {
        const response = decryptedResponse.body.data.message;
        if (response.id === 9) {
          this.router.navigate(['/email', this.deleteControl.value.trim()])
          console.log('success');
        }
        console.error('error al enviar correo');
      } else {
        console.error('error al enviar correo');
      }
    } catch (error) {
      console.error('error al enviar correo', error);
    }
  }

  async doEmail () {
    this.processing = true;
    const valid = await this.validateEmail();
    if (!valid) {
      this.processing = false;
      this.existEmail = true;
      return;
    }
    this.sendEmail();
  }

}
