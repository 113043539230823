<main class="main">
  <section class="content">
    <router-outlet></router-outlet>
  </section>
  <section class="logo">
    <img src="assets/logo.png" alt="Logo" width="400" height="140">
  </section>
</main>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#00A3B3" type="ball-atom" [fullScreen]="true">
  <p style="color: #00A3B3"> Cargando... </p>
</ngx-spinner>
